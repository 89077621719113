import React from 'react';

import * as JunoLogoStyles from './JunoLogo.module.scss';

const JunoLogo = ({ parentStyles, showHackerYou = true }) => {
  const viewBox = showHackerYou ? '0 0 580 129' : '0 0 312 129';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={parentStyles}
      role="img"
      aria-labelledby="logoLabel"
    >
      <title id="logoLabel">Juno formerly Hacker You (Logo)</title>
      <g id="_06_Wordmark-FullName" data-name="06_Wordmark-FullName">
        {/* "JUNO" */}
        <path
          className={JunoLogoStyles.path}
          d="M111.42,101.62H141l-11-11V25.52h-29.6l11,11V83.64a24.56,24.56,0,0,1-7.59,6.82,18.23,18.23,0,0,1-9,2.33c-5,0-8.44-1.68-10.64-4.31-2.36-2.81-3-7.68-3-15.22V25.52H51.59l11,11v42q0,11.77,6,18.29t17.21,6.51a29.31,29.31,0,0,0,14.33-3.8,30.66,30.66,0,0,0,11.24-10.62Z"
        />
        <path
          className={JunoLogoStyles.path}
          d="M300.84,34.2q-9.3-10.38-24.34-10.39-18.9,0-30.61,12.56t-11.7,30.69q0,15.66,9.3,26t24.33,10.38q18.92,0,30.62-12.55t11.7-30.69Q310.14,44.58,300.84,34.2ZM286.89,90.85q-3.88,6.74-10.54,6.74-9.77,0-16.12-12.79T253.88,53q0-11.93,3.56-17.59t10.7-5.66q9.76,0,16.19,12.87t6.43,31.77Q290.76,84.1,286.89,90.85Z"
        />
        <path
          className={JunoLogoStyles.path}
          d="M171.05,38.23V25.52h-29.6l11,11v65.1h29.61l-11-11V43.5a24.59,24.59,0,0,1,7.6-6.82,18.2,18.2,0,0,1,9-2.33c5,0,8.43,1.68,10.64,4.31,2.35,2.81,3,7.68,3,15.22v47.74h29.6l-11-11v-42q0-11.78-6-18.29t-17.2-6.51a29.38,29.38,0,0,0-14.34,3.8A30.66,30.66,0,0,0,171.05,38.23Z"
        />
        <path
          className={JunoLogoStyles.path}
          d="M10.58,0l12.5,12.5v90.83c0,4.6-.15,6.92-.69,8.44H0V129H15.33s20-19.69,22.78-23.5a15.12,15.12,0,0,0,3.25-10V0Z"
        />

        {/* "FORMERLY HACKERYOU" */}
        {showHackerYou && (
          <>
            <path
              className={JunoLogoStyles.path}
              d="M368.17,27.8V38.25h14.32v3.31H368.17v13.2h-3.7V24.4h19.78v3.4Z"
            />
            <path
              className={JunoLogoStyles.path}
              d="M396.21,32.57c6.58,0,11.05,4.65,11.05,11.36s-4.47,11.26-11.05,11.26-11-4.6-11-11.26S389.63,32.57,396.21,32.57Zm0,19.57c4.43,0,7.39-3.27,7.39-8.26s-3-8.3-7.39-8.3-7.4,3.23-7.4,8.3S391.78,52.14,396.21,52.14Z"
            />
            <path
              className={JunoLogoStyles.path}
              d="M423.3,33v3.27h-2.88c-3.75,0-5.42,2.45-5.42,6.49v12h-3.53V33h3.1l.47,3.1a6.19,6.19,0,0,1,5.5-3.1Z"
            />
            <path
              className={JunoLogoStyles.path}
              d="M457.91,41V54.76h-3.57V41.65c0-4.13-2-5.81-5-5.81S444,37.78,443.85,42V54.76h-3.48V42c0-4-1.85-6.11-5-6.11-3.44,0-5.5,2.24-5.5,5.94v13h-3.53V33h3.1l.51,2.54a6.62,6.62,0,0,1,6-3c3.27,0,5.85,1.42,7.09,4.22a7.44,7.44,0,0,1,7-4.22C454.77,32.57,457.91,35.76,457.91,41Z"
            />
            <path
              className={JunoLogoStyles.path}
              d="M483.15,44.87H465.69c.09,4.82,3.23,7.31,7.14,7.31a6.5,6.5,0,0,0,6.45-4.26h3.61c-1.24,4.35-5,7.23-10.14,7.23-6.41,0-10.67-4.52-10.67-11.31s4.26-11.27,10.62-11.27,10.45,4.3,10.45,10.75Zm-17.41-2.62h13.8c-.26-4.52-3.18-6.8-6.84-6.8S466.17,37.69,465.74,42.25Z"
            />
            <path
              className={JunoLogoStyles.path}
              d="M499.23,33v3.27h-2.88c-3.74,0-5.42,2.45-5.42,6.49v12h-3.52V33h3.09l.48,3.1a6.18,6.18,0,0,1,5.5-3.1Z"
            />
            <path className={JunoLogoStyles.path} d="M505.81,54.76h-3.52V23.11h3.52Z" />
            <path
              className={JunoLogoStyles.path}
              d="M519.27,52.4,525.85,33l3.74,0-9.12,25.29c-1.54,4.21-3.91,5.07-7.09,5.07h-4.3V60.18h4.34c2.24,0,3.62-1.2,3.62-3.66a5.28,5.28,0,0,0-.39-1.89L508.31,33h3.82Z"
            />
            <path
              className={JunoLogoStyles.path}
              d="M368.17,88.09v13.67h-3.7V71.4h3.7V84.65h16.56V71.4h3.65v30.36h-3.65V88.09Z"
            />
            <path
              className={JunoLogoStyles.path}
              d="M413.71,99v2.75h-2.15c-1.72,0-3.31-.69-3.53-3-1.29,2-4.13,3.4-7.61,3.4-4.86,0-7.87-2.54-7.87-6.66,0-3.49,2.15-6.28,8.34-6.28h6.88V88c0-3.91-2.27-5.54-5.5-5.54s-5.37,1.59-5.55,4.17h-3.39c.25-4.6,4-7.1,9.07-7.1,5.42,0,8.94,3,8.94,8.43v9.33c0,1.38.35,1.68,1.59,1.68Zm-5.94-7.14h-6.53c-3.74,0-5,1.55-5,3.61,0,2.54,2,3.79,4.81,3.79,3.57,0,6.75-2,6.75-6.41Z"
            />
            <path
              className={JunoLogoStyles.path}
              d="M426.61,82.63c-4,0-7.05,2.83-7.05,8.21s3.05,8.21,7,8.21a6.66,6.66,0,0,0,6.5-4.9h3.74a10.12,10.12,0,0,1-10.24,8c-6.36,0-10.62-4.52-10.62-11.31s4.26-11.27,10.62-11.27c5.12,0,8.95,2.84,10.2,7.7h-3.87A6.51,6.51,0,0,0,426.61,82.63Z"
            />
            <path
              className={JunoLogoStyles.path}
              d="M440.45,70.11H444V90.67L454.51,80H459l-9.42,9.51L459,101.76H454.6L447.08,92l-3.06,3v6.71h-3.57Z"
            />
            <path
              className={JunoLogoStyles.path}
              d="M480.83,91.87H463.37c.09,4.82,3.23,7.31,7.14,7.31A6.52,6.52,0,0,0,477,94.92h3.61c-1.24,4.35-5,7.23-10.15,7.23-6.4,0-10.66-4.52-10.66-11.31S464,79.57,470.38,79.57s10.45,4.3,10.45,10.75Zm-17.41-2.62h13.8c-.26-4.52-3.18-6.8-6.84-6.8S463.85,84.69,463.42,89.25Z"
            />
            <path
              className={JunoLogoStyles.path}
              d="M496.91,80v3.27H494c-3.74,0-5.42,2.45-5.42,6.49v12h-3.52V80h3.09l.48,3.1a6.18,6.18,0,0,1,5.5-3.1Z"
            />
            <path
              className={JunoLogoStyles.path}
              d="M495.43,71.4h4.47l8.69,16.09,8.72-16.09h4.39l-11.35,20v10.36h-3.66V91.4Z"
            />
            <path
              className={JunoLogoStyles.path}
              d="M531.16,79.57c6.58,0,11.05,4.65,11.05,11.36s-4.47,11.26-11.05,11.26-11-4.6-11-11.26S524.58,79.57,531.16,79.57Zm0,19.57c4.43,0,7.4-3.27,7.4-8.26s-3-8.3-7.4-8.3-7.4,3.23-7.4,8.3S526.73,99.14,531.16,99.14Z"
            />
            <path
              className={JunoLogoStyles.path}
              d="M564.74,80v21.76h-3l-.34-3.22a7.25,7.25,0,0,1-6.71,3.65c-5.29,0-8.47-3.61-8.47-9.16V80h3.44V92.47c0,4.43,2.36,6.54,5.72,6.5,3.91,0,6-2.88,6-7.06V80Z"
            />
          </>
        )}
      </g>
    </svg>
  );
};

export default JunoLogo;
